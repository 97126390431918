import { Link } from "react-router-dom";

export default function MobileWarning() {
  return (
    <div className="fixed sm:hidden top-0 left-0 inset-1 z-[10000] bg-white bg-opacity-75 flex flex-col items-center justify-center text-white">
      <div className="px-4 py-8 bg-gray-800 rounded-lg shadow-xl max-w-sm md:max-w-lg mx-auto text-center">
        <img
          src={`${process.env.PUBLIC_URL}/header/BouwruimteNL_logo.png`}
          alt="Bouwruimte NL logo"
          className="h-16 mx-auto"
        />
        <h1 className="text-3xl font-bold mt-6">Let op</h1>
        <p className="text-lg mt-4">
          Deze pagina is momenteel alleen geoptimaliseerd voor grote schermen en
          werkt mogelijk niet correct op kleine schermen{" "}
        </p>
        <img
          src={`${process.env.PUBLIC_URL}/noPhone.svg`}
          className="h-40 mt-4 mx-auto"
          alt="Geen mobiele ondersteuning"
        />
        <p className="text-lg my-4">
          Wij raden u aan een groter scherm te gebruiken om toegang te krijgen
          tot alle functies.
        </p>

        <Link
          to={process.env.PUBLIC_URL}
          className="px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondaryOrange hover:brightness-110 hover:shadow-none transition-all"
        >
          Terug naar Homepagina
        </Link>
      </div>
    </div>
  );
}
