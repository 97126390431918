import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="container mx-auto px-4 md:px-10 lg:px-40">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
          <div>
            <h1 className="text-6xl font-bold text-gray-800 mb-4">Ooops...</h1>
            <h2 className="text-4xl text-blue-600 font-semibold mb-6">
              Pagina niet gevonden
            </h2>
            <p className="text-lg text-gray-600 mb-8">
              De pagina waarnaar u zoekt bestaat niet of er is een andere fout
              opgetreden. Ga terug naar de startpagina.
            </p>
            <Link to={process.env.PUBLIC_URL} className="inline-block">
              <button className="bg-blue-600 text-white rounded-lg shadow-lg px-10 py-3 text-xl hover:bg-blue-700 transition duration-150 ease-in-out transform hover:scale-105">
                Startpagina
              </button>
            </Link>
          </div>

          <div className="flex justify-center md:justify-end">
            <img
              src={`${process.env.PUBLIC_URL}/notFound.png`}
              alt="Page Not Found"
              className="max-w-sm md:max-w-md lg:max-w-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
