// React Core
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

// Routing
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

// Internationalization
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";

// Translation files
import translation_dutch from "./locales/dutch/translation_dutch.json";
import translation_en from "./locales/en/translation_en.json";

// Styles
import "./index.css";
import "./i18next.js";

// General components
import App from "./App.jsx";
import Footer from "./components/Layout/Footer/index.jsx";
import Header from "./components/Layout/Header/index.jsx";
import NotFound from "./components/NotFound.jsx";

// Specific Pages
import Success from "./Success.jsx";
import Cancel from "./Cancel.jsx";
import Contact from "./Contact.jsx";

// Specific components within components folder
import Disclaimer from "./components/Disclaimer/index.jsx";
import Question from "./components/Question/index.jsx";
import MobileWarning from "./components/Common/MobileWarning.jsx";
import Map from "./components/Map/index.jsx";
import CookiePopup from "./components/CookiePopup.jsx";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "dutch",
  resources: {
    en: {
      global: translation_en,
    },
    dutch: {
      global: translation_dutch,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Suspense fallback={<div>loading ...</div>}>
        <BrowserRouter basename="/">
          <Header />
          <MainContent />
          <Footer />
        </BrowserRouter>
      </Suspense>
    </I18nextProvider>
  </React.StrictMode>
);

function MainContent() {
  const location = useLocation();

  return (
    <main className="bg-primaryGray !w-screen">
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/search" element={<Map />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/question" element={<Question />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {location.pathname === "/search" && <MobileWarning />}

      <CookiePopup />
    </main>
  );
}
