import { IoIosWarning } from "react-icons/io";
import { useScrollAnimate } from "../../../../hooks/useScrollAnimate";
import { motion } from "framer-motion";

export default function WarningIcon() {
  const { controls } = useScrollAnimate();

  return (
    <>
      {" "}
      <motion.div
        variants={{
          hidden: { opacity: 0, rotate: -45, x: -20, y: -20 },
          visible: { opacity: 1, rotate: 45, x: 0, y: 0 },
        }}
        initial="hidden"
        animate={controls}
        transition={{
          duration: 0.5,
          delay: 0.6,
        }}
        className="absolute -top-10 -right-0 lg:right-20 rotate-45 z-10 hidden lg:block"
      >
        <div className="bg-secondaryBlue border-[10px] lg:border-[15px] rounded-full p-4 flex items-center justify-center aspect-square">
          <IoIosWarning className="text-[80px] lg:text-[150px] -mt-5 text-secondaryGray" />
        </div>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0, rotate: -45, x: -20, y: -20 },
          visible: { opacity: 0.1, rotate: 45, x: 0, y: 0 },
        }}
        initial="hidden"
        animate={controls}
        transition={{
          duration: 0.5,
          delay: 0.6,
        }}
        className="absolute -top-20 -right-10 rotate-45 z-10"
      >
        <div className="bg-secondaryBlue border-[10px] lg:border-[15px] rounded-full p-4 flex items-center justify-center aspect-square">
          <IoIosWarning className="text-[150px] -mt-5 text-secondaryGray" />
        </div>
      </motion.div>
    </>
  );
}
