import { motion } from "framer-motion";
import { useScrollAnimate } from "../../hooks/useScrollAnimate";

export default function ScaleOut({ children, delay, duration, className }) {
  const { ref, controls } = useScrollAnimate();

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, scale: 0 }}
      variants={{
        hidden: { opacity: 0, scale: 0 },
        visible: { opacity: 1, scale: 1 },
      }}
      animate={controls}
      transition={{ delay: delay, duration: duration, ease: "easeIn" }}
      className={className}
    >
      {children}
    </motion.div>
  );
}
