import Text from "./Text";
import Image from "./Image";
import Buttons from "./Buttons";
import AnimatedTitle from "../../../Common/AnimatedTitle";
import { useTranslation } from "react-i18next";

export default function Hero() {
  const { t } = useTranslation("global");

  return (
    <div className="relative z-10 mt-14 pb-20 grid md:grid-cols-2 gap-x-10 px-6 sm:px-20 md:px-10 xl:px-32">
      <AnimatedTitle
        className="text-3xl text-center md:hidden mb-6 font-bold text-primaryBlue leading-[1.2]"
        extraDelay={0.6}
        duration={0.5}
      >
        {t("hero.part1")}
      </AnimatedTitle>

      <Image />

      <div className="relative my-auto z-20">
        <Text />

        <Buttons />
      </div>
    </div>
  );
}
