import "../../styles/input.css";
import "../../styles/alert.css";

import { useEffect, useRef } from "react";
import { saveAs } from "file-saver";
import { projectStorage } from "../../firebase/config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import useMapState from "../helpers/mapState";
import MainForm from "./MainForm";
import Pay from "./Pay";
import CheckBoxes from "./CheckBoxes";
import Loader from "./Loader";
import useScrollToTop from "../../hooks/useScrollToTop";

export default function Map() {
  const mapRef = useRef(null);
  useScrollToTop();

  const {
    name,
    houseltr,
    huisnummertoevoeging,
    email,
    setpay,
    featureList,
    cadstralcode,
    postcode,
    housenb,
  } = useMapState();

  const convertToGeoJson = () => {
    return {
      type: "FeatureCollection",
      name: "1",
      naam: name,
      date: new Date().toISOString(),
      email: email,
      Searched_Postcode: postcode,
      Searched_Housenb: housenb,
      Searched_Houseltr: houseltr,
      Searched_Huisnummertoevoeging: huisnummertoevoeging,
      cadstralcode: cadstralcode,
      features: featureList,
    };
  };

  const sendReq = () => {
    setpay(true);
    const geojsonData = convertToGeoJson();

    const jsonOutput = JSON.stringify(geojsonData, null, 2);
    const blob = new Blob([jsonOutput], { type: "application/json" });
    if (huisnummertoevoeging === "") {
      const filename = postcode + housenb + houseltr;
      // saveAs(blob, filename);
      const docRef = ref(projectStorage, filename);
      uploadBytes(docRef, blob).then(() => {
        getDownloadURL(docRef).then((url) => {
          alert("doc uploaded");
        });
      });
    } else {
      const filename =
        postcode + housenb + houseltr + "-" + huisnummertoevoeging;
      // saveAs(blob);
      const docRef = ref(projectStorage, filename);
      uploadBytes(docRef, blob).then(() => {
        getDownloadURL(docRef).then((url) => {
          // alert("doc uploaded");
        });
      });
    }
  };

  useEffect(() => {
    const searchElement = document.getElementById("logo-input");
    if (searchElement) {
      searchElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div>
      <div className="hidden md:grid grid-cols-5 px-10 gap-x-10 pb-10 relative">
        <div className="my-auto mx-auto col-span-2">
          <MainForm sendReq={sendReq} mapRef={mapRef} />

          <CheckBoxes sendReq={sendReq} />
        </div>

        <Pay mapRef={mapRef} />
      </div>
    </div>
  );
}
