import { useTranslation } from "react-i18next";
import AnimatedTitle from "../../../Common/AnimatedTitle";
import RevealingFromBottom from "../../../Common/RevealingFromBottom";

export default function Title() {
  const { t } = useTranslation("global");

  return (
    <div className="text-center mb-10 lg:mb-20">
      <AnimatedTitle
        duration={0.6}
        className="text-3xl lg:text-4xl font-semibold text-primaryBlue mb-1 lg:mb-0"
      >
        {t("warningsSection.title")}
      </AnimatedTitle>

      <RevealingFromBottom
        duration={0.6}
        extraDelay={0.2}
        className="lg:w-[40%] mx-auto text-sm lg:text-base"
      >
        {t("warningsSection.subtitle")}
      </RevealingFromBottom>
    </div>
  );
}
