import { motion } from "framer-motion";
import DownloadButton from "./DownloadButton";
import { useScrollAnimate } from "../../../hooks/useScrollAnimate";

export default function SingleCard({ index, singleCard }) {
  const { ref, controls } = useScrollAnimate();

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
      }}
      transition={{ delay: 0.2 * index, duration: 0.5, ease: "easeIn" }}
      className="rounded-xl overflow-hidden shadow-jubilation bg-primaryGray/40 group"
    >
      <div className="w-full overflow-hidden h-[320px]">
        <img
          src={singleCard.img}
          alt="charts1"
          className="h-[320px] w-full object-cover duration-700 group-hover:scale-105 transition-all object-center"
        />
      </div>

      <div className="p-4 flex flex-col justify-between h-[160px]">
        <p className="text-center text-gray-600">{singleCard.title}</p>

        <DownloadButton index={index} />
      </div>
    </motion.div>
  );
}
