import React from "react";
import { useTranslation } from "react-i18next";

export default function ArrowSection() {
  const { t } = useTranslation("global");

  return (
    <div className="flex flex-col items-center mt-14 lg:mt-20 mb-5">
      <div className="rotate-[65deg] mb-20 hidden lg:block">
        <Arrow />
      </div>

      <div className="rotate-[65deg] mb-14 lg:hidden block">
        <ArrowSmall />
      </div>

      <p className="text-lg font-medium text-primaryBlue">
        {t("privacySection.pleaseAgreeMessage")}
      </p>
    </div>
  );
}

function Arrow() {
  return (
    <svg
      width="250"
      height="90"
      viewBox="0 0 285 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-5.04369e-05 16.3481C23.1319 -2.25672 101.507 -4.82142 149.365 10.5526C201.129 27.0532 226.801 45.5042 271.759 99.1C272.766 94.9409 273.226 91.4529 274.173 88.4228C275.105 84.9233 276.126 81.2337 277.825 78.0914C278.268 77.141 280.79 76.2339 282.027 76.5796C283.265 76.9254 284.56 79.1491 284.5 80.2782C283.692 91.0103 282.502 101.564 281.414 112.397C280.954 115.885 278.741 117.63 275.042 117.062C261.174 114.299 247.218 111.725 233.248 108.682C230.11 107.913 227.017 105.545 223.186 103.759C230.062 97.0136 236.514 101.179 242.468 101.41C248.703 101.54 254.878 102.8 262.645 103.644C251.713 85.5865 238.727 71.4313 223.632 59.3007C168.464 14.5984 104.979 0.7322 35.7945 10.5908C26.7723 11.9379 17.97 14.3134 9.05069 15.9399C6.50072 15.908 4.14225 15.9654 -5.04369e-05 16.3481Z"
        fill="#FF8934"
      />
    </svg>
  );
}

function ArrowSmall() {
  return (
    <svg
      width="180"
      height="60"
      viewBox="0 0 285 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-5.04369e-05 16.3481C23.1319 -2.25672 101.507 -4.82142 149.365 10.5526C201.129 27.0532 226.801 45.5042 271.759 99.1C272.766 94.9409 273.226 91.4529 274.173 88.4228C275.105 84.9233 276.126 81.2337 277.825 78.0914C278.268 77.141 280.79 76.2339 282.027 76.5796C283.265 76.9254 284.56 79.1491 284.5 80.2782C283.692 91.0103 282.502 101.564 281.414 112.397C280.954 115.885 278.741 117.63 275.042 117.062C261.174 114.299 247.218 111.725 233.248 108.682C230.11 107.913 227.017 105.545 223.186 103.759C230.062 97.0136 236.514 101.179 242.468 101.41C248.703 101.54 254.878 102.8 262.645 103.644C251.713 85.5865 238.727 71.4313 223.632 59.3007C168.464 14.5984 104.979 0.7322 35.7945 10.5908C26.7723 11.9379 17.97 14.3134 9.05069 15.9399C6.50072 15.908 4.14225 15.9654 -5.04369e-05 16.3481Z"
        fill="#FF8934"
      />
    </svg>
  );
}
