import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Verified({ verifyread }) {
  const { t } = useTranslation("global");

  return (
    <>
      {verifyread && (
        <div className="flex justify-center">
          <Link to="/search">
            <div
              id="start"
              className="flex lg:w-[850px] px-8 lg:px-20 py-5 rounded-xl shadow-jubilation items-center bg-gradient-to-r from-secondaryOrange to-secondaryOrange/70 hover:to-secondaryOrange/80 justify-center gap-x-4 hover:shadow-none hover:gap-x-8 transition-all"
            >
              <span className="text-lg md:text-2xl lg:text-4xl text-white font-medium">
                {t("privacySection.startBtn")}
              </span>
              <img
                src={`${process.env.PUBLIC_URL}/warning/arrow.png`}
                className="h-[20px] lg:h-[40px] aspect-auto"
                alt=""
              />
            </div>
          </Link>
        </div>
      )}
    </>
  );
}
