import { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import WarningsBox from "./WarningBox";
import PrivacyBox from "./PrivacyBox";
import ArrowSection from "./ArrowSection";
import Verified from "./Buttons/Verified";
import Unverified from "./Buttons/Unverified";

export default function Warnings() {
  const [stepsComplete, setStepsComplete] = useState(0);
  const [verifyread, setverifyread] = useState(false);
  const [read, setRead] = useState(false);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleVerifyread = () => {
    const check = document.getElementById("verifyread");

    if (check.checked) {
      setverifyread(true);
      localStorage.setItem("verified", "true");

      setTimeout(() => {
        handleClickScroll("start");
      }, [100]);
    } else {
      setverifyread(false);
    }
  };

  const handleDownloadPdf = () => {
    let filename = "Privacy_Verklaring.pdf";
    const file = `${process.env.PUBLIC_URL}/downloads/Privacy_Verklaring.pdf`;

    fetch(file)
      .then(function (response) {
        if (response.status === 200 || response.status === 0) {
          return Promise.resolve(response.blob());
        } else {
          return Promise.reject(new Error(response.statusText));
        }
      })
      .then((blob) => saveAs(blob, filename))
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (stepsComplete === 8) {
      setRead(true);
    }
  }, [stepsComplete]);

  useEffect(() => {
    if (localStorage.getItem("verified") === "true") {
      setverifyread(true);
      setRead(true);
    }
  }, []);

  return (
    <div id="warn" className="mt-10 pt-10 pb-20 relative px-5 lg:px-0">
      <WarningsBox
        stepsComplete={stepsComplete}
        setStepsComplete={setStepsComplete}
        read={read}
      />

      <PrivacyBox
        handleVerifyread={handleVerifyread}
        handleDownloadPdf={handleDownloadPdf}
        read={read}
      />

      <ArrowSection />

      <Verified verifyread={verifyread} />

      <Unverified verifyread={verifyread} />
    </div>
  );
}
