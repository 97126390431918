import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useMapState from "../../../helpers/mapState";
import InputComp from "../../Common/InputComp";
import { motion, AnimatePresence } from "framer-motion";
import { TiWarning } from "react-icons/ti";

export default function SearchforHouse() {
  const { t } = useTranslation("global");

  const [openError, setOpenError] = useState(false);

  const {
    houseltr,
    sethouseltr,
    huisnummertoevoeging,
    sethuisnummertoevoeging,
    email,
    setemail,
    postcode,
    setPostcode,
    housenb,
    setHousenb,
    error,
    setError,
    setstartsarch,
    setlatrd,
    setlngrd,
    setadress,
    setshowSituation,
    searchforHouse,
  } = useMapState();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const alertSure = () => {
    setshowSituation(true);
    setstartsarch(true);
    const houseurl = `https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest?q=${postcode}&start=0&rows=1000&fl=*`;

    fetch(houseurl)
      .then((res) => res.json())
      .then((datahouse) => {
        let hs = datahouse["response"]["docs"].filter(
          (item) => item.huisnummer === Number(housenb)
        );

        if (hs.length > 1) {
          const hslt = hs;
          hs = hs.filter((item) => item.huisletter === houseltr);
          if (hs.length === 0) {
            hs = hslt.filter(
              (item) => item.huisnummertoevoeging === huisnummertoevoeging
            );
            if (hs.length === 0) {
              hs = hslt[0];
            }
          } else if (hs.length > 1) {
            hs = hs.filter(
              (item) => item.huisnummertoevoeging === huisnummertoevoeging
            );
          }
        }

        if (hs[0] && hs[0]["weergavenaam"]) {
          setadress(hs[0]["weergavenaam"]);
        } else if (hs["weergavenaam"]) {
          setadress(hs["weergavenaam"]);
        }
        if (hs[0] && hs[0]["huisletter"]) {
          sethouseltr(hs[0]["huisletter"]);
        }

        let inputString =
          hs[0] && hs[0]["geometrie_rd"] ? hs[0]["geometrie_rd"] : "";

        let splitNumbers;

        // Safely use match only if inputString is defined
        if (inputString) {
          const match = inputString.match(
            /POINT\((\d+(\.\d+)?)\s(\d+(\.\d+)?)\)/
          );

          if (match) {
            splitNumbers = [parseFloat(match[1]), parseFloat(match[3])];
          } else {
            setOpenError(true);
          }
        } else {
          setOpenError(true);
        }

        // Additional logic to handle the absence of coordinates
        if (!splitNumbers || splitNumbers.length < 2) {
          setOpenError(true);
        } else {
          if (parseFloat(splitNumbers[1]) > parseFloat(splitNumbers[0])) {
            setlatrd(parseFloat(splitNumbers[0]));
            setlngrd(parseFloat(splitNumbers[1]));
          } else {
            setlatrd(parseFloat(splitNumbers[1]));
            setlngrd(parseFloat(splitNumbers[0]));
          }
        }
      })
      .catch((error) => {
        console.error("Failed to fetch data: ", error);
      });
  };

  const handleChangeEmail = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("e-mail is ongeldig");
    } else {
      setError(null);
    }

    setemail(event.target.value);
  };

  useEffect(() => {
    let timer;
    if (openError) {
      timer = setTimeout(() => {
        setOpenError(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [openError]);

  return (
    <>
      {searchforHouse && (
        <div>
          <p className="text-2xl font-semibold text-gray-600 mb-6">
            Zoek uw huis
          </p>

          <div className="space-y-4 w-[550px]">
            <div className="relative">
              <InputComp
                label={t("questions.4")}
                name="email"
                placeholder="email"
                value={email}
                onChange={handleChangeEmail}
              />

              {error && (
                <div className="absolute right-0 -bottom-4">
                  <h2 className="text-xs font-semibold text-red-500">
                    Ongeldige e-mail
                  </h2>
                </div>
              )}
            </div>

            <div className="grid grid-cols-2 gap-x-4">
              <InputComp
                label={t("questions.12")}
                name="postcode"
                placeholder="1234AB"
                value={postcode}
                maxLength={6}
                onChange={(e) => {
                  setPostcode(e.target.value);
                }}
              />

              <InputComp
                label={t("questions.13")}
                name="houseNb"
                placeholder="22"
                maxLength={4}
                value={housenb}
                onChange={(e) => {
                  setHousenb(e.target.value);
                }}
              />
            </div>

            <div className="grid grid-cols-2 gap-x-4">
              <InputComp
                label={t("questions.14")}
                name="houseLtr"
                placeholder="_"
                maxLength={1}
                value={houseltr}
                onChange={(e) => {
                  sethouseltr(e.target.value);
                }}
              />

              <InputComp
                label={t("questions.15")}
                name="huisnummertoevoeging"
                placeholder="_"
                maxLength={4}
                value={huisnummertoevoeging}
                onChange={(e) => {
                  sethuisnummertoevoeging(e.target.value);
                }}
              />
            </div>

            <button
              className="primary-btn !mt-6"
              onClick={alertSure}
              disabled={
                (postcode !== ""
                  ? housenb !== 0
                    ? email !== ""
                      ? false
                      : true
                    : true
                  : true) || error
              }
            >
              {t("button.5")}
            </button>
          </div>
        </div>
      )}

      <AnimatePresence>
        {openError && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
            className="fixed z-[100] top-5 right-5 bg-white rounded shadow-lg p-3 flex items-center space-x-2"
          >
            <TiWarning className="text-red-500 text-xl" />
            <p className="text-sm font-medium text-red-600">
              Het opgegeven adres is niet gevonden.
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
