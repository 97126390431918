import AnimatedTitle from "../Common/AnimatedTitle";
import RevealingFromBottom from "../Common/RevealingFromBottom";
import { useTranslation } from "react-i18next";

export default function Text() {
  const { t } = useTranslation("global");

  return (
    <>
      <AnimatedTitle
        extraDelay={0.5}
        duration={0.5}
        className="text-3xl m-5 lg:m lg:text-5xl text-white font-bold"
      >
        {t("disclaimer.title")}
      </AnimatedTitle>

      <div className="text-primaryGray p-5 lg:p-0 mt-4 space-y-3">
        {t("disclaimer.content")
          .split("|")
          .map((text, index) => (
            <RevealingFromBottom extraDelay={0.5 + index / 10} duration={0.7}>
              {text}
            </RevealingFromBottom>
          ))}
      </div>
    </>
  );
}
