import { handleClickScroll } from "../../../../helpers/handleClickScroll";
import ScaleOut from "../../../Common/ScaleOut";
import { useTranslation } from "react-i18next";
import { GoDotFill } from "react-icons/go";

export default function Buttons() {
  const { t } = useTranslation("global");

  return (
    <div className="lg:w-fit w-full">
      <div className="flex lg:flex-row flex-col items-center w-full gap-4 z-20 mt-10">
        <ScaleOut className="w-full lg:w-fit" duration={0.6} delay={1}>
          <button
            onClick={() => handleClickScroll("howworks")}
            className="rounded-xl w-full hover:scale-110 active:scale-95 text-white px-8 py-2 bg-gradient-to-r from-secondaryOrange to-secondaryOrange/60 hover:brightness-110 hover:shadow-none transition-all duration-500 shadow-warm"
          >
            {t("hero.btn1")}
          </button>
        </ScaleOut>

        <ScaleOut className="flex justify-center" duration={0.6} delay={1.05}>
          <GoDotFill className="text-primaryBlue text-sm" />
        </ScaleOut>

        <ScaleOut className="w-full lg:w-fit" duration={0.6} delay={1.1}>
          <button
            onClick={() => handleClickScroll("about")}
            className="px-6 py-2 w-full border-[3px] hover:scale-105 lg:scale-95 active:scale-90 bg-white border-secondaryOrange rounded-xl font-semibold text-secondaryOrange shadow-warm hover:shadow-none transition-all duration-500"
          >
            {t("hero.btn2")}
          </button>
        </ScaleOut>
      </div>

      <ScaleOut
        className="w-full flex justify-center mt-4"
        duration={0.6}
        delay={1.05}
      >
        <button
          onClick={() => handleClickScroll("warn")}
          className="px-6 py-2 w-full border-[3px] hover:scale-105 active:scale-90 bg-white border-secondaryOrange rounded-xl font-semibold text-secondaryOrange shadow-warm hover:shadow-none transition-all duration-500"
        >
          Start de aanvrag
        </button>
      </ScaleOut>
    </div>
  );
}
