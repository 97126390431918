import { easeInOut, motion } from "framer-motion";

export default function ContactBG() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7, easings: easeInOut, delay: 0.3 }}
      className="absolute top-0 -right-[100px]"
    >
      <svg
        width="245"
        height="70"
        viewBox="0 0 245 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.9"
          d="M39.2 0H245V66.6228H0C15.3086 40.605 23.8914 26.0178 39.2 0Z"
          fill="url(#paint0_linear_139_64)"
        />
        <path
          opacity="0.8"
          d="M65.1624 0H245V66.6228H25.0732L65.1624 0Z"
          fill="url(#paint1_linear_139_64)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_139_64"
            x1="245"
            y1="-4.96379e-05"
            x2="-4.40356"
            y2="36.6864"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#D3D3D3" />
            <stop offset="0.563154" stop-color="#D3D3D3" stop-opacity="0.5" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_139_64"
            x1="59.9866"
            y1="-2.45517e-05"
            x2="250.757"
            y2="29.9434"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.373154" stop-color="#D3D3D3" stop-opacity="0.8" />
            <stop offset="0.555" stop-color="#D3D3D3" />
          </linearGradient>
        </defs>
      </svg>
    </motion.div>
  );
}
