import { motion } from "framer-motion";
import { useScrollAnimate } from "../../hooks/useScrollAnimate";
import useScrollToTop from "../../hooks/useScrollToTop";
import Links from "./Links";
import Text from "./Text";

export default function Disclaimer() {
  const { ref, controls } = useScrollAnimate();

  useScrollToTop();

  return (
    <div className="md:h-[90vh] py-20 md:py-0 px-5 flex items-center justify-center">
      <div className="flex flex-col-reverse md:flex-row max-w-5xl mx-auto rounded-2xl overflow-hidden shadow-jubilation">
        <div className="bg-primaryBlue md:p-8">
          <Text />

          <Links />
        </div>

        <motion.img
          ref={ref}
          initial={{ opacity: 0, scale: 0 }}
          variants={{
            hidden: { opacity: 0, scale: 0 },
            visible: { opacity: 1, scale: 1 },
          }}
          animate={controls}
          transition={{ delay: 0.6, duration: 0.7, ease: "easeIn" }}
          className="h-[200px] md:h-[400px] aspect-auto my-auto mx-auto"
          src={`${process.env.PUBLIC_URL}/disclaimer/disclaimer.png`}
          alt=""
        />
      </div>
    </div>
  );
}
