import { useTranslation } from "react-i18next";
import { handleClickScroll } from "../../../../helpers/handleClickScroll";
import { useLocation } from "react-router-dom";
import { easeInOut, motion } from "framer-motion";
import Contact from "./Contact";
import StyledLink from "./StyledLink";
import Logo from "./Logo";

export default function DesktopHeader() {
  const { t } = useTranslation("global");
  const location = useLocation();

  return (
    <nav className="lg:flex hidden relative z-30 w-screen justify-between bg-primaryGray shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
      <div className="flex items-center gap-x-10 pl-5 xl:pl-20">
        <Logo />

        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, easings: easeInOut, delay: 0.3 }}
          className="flex items-center text-primaryBlue font-semibold gap-x-3 lg:gap-x-6"
        >
          {location.pathname === "/" ||
          location.pathname === "/acceptance" ||
          location.pathname === "/acceptance/" ? (
            <>
              <p
                className="cursor-pointer group"
                onClick={() => handleClickScroll("howworks")}
              >
                {t("nav.wedo")}
                <div className="bg-secondaryOrange h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
              </p>

              <p
                className="cursor-pointer group"
                onClick={() => handleClickScroll("about")}
              >
                {t("nav.about")}
                <div className="bg-secondaryOrange h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
              </p>
            </>
          ) : (
            <>
              <StyledLink to={process.env.PUBLIC_URL} text={t("nav.wedo")} />

              <StyledLink to={process.env.PUBLIC_URL} text={t("nav.about")} />
            </>
          )}

          <StyledLink
            to="/disclaimer"
            text={t("nav.disclaimer")}
            selected={location.pathname === "/disclaimer"}
          />

          <StyledLink
            to="/question"
            text={t("nav.faqs")}
            selected={location.pathname === "/question"}
          />
        </motion.div>
      </div>

      <Contact />
    </nav>
  );
}
