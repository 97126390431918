import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";

export default function Header() {
  return (
    <>
      <DesktopHeader />

      <MobileHeader />
    </>
  );
}
