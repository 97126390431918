import ScaleOut from "../Common/ScaleOut";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Links() {
  const { t } = useTranslation("global");

  return (
    <div className="grid grid-cols-2 md:flex items-center pb-4 px-4 md:px-0 md:pb-0 gap-x-4 mt-4 md:mt-14">
      <ScaleOut delay={0.8} duration={0.5}>
        <Link
          to={process.env.PUBLIC_URL === "/acceptance" ? "/acceptance" : "/"}
        >
          <button className="bg-white text-primaryBlue rounded-xl shadow-warm py-2 px-4 shadow-white/30 hover:scale-105 hover:shadow-none transition-all font-medium hover:font-semibold w-full md:w-[160px]">
            <span> {t("disclaimer.btn1")}</span>
          </button>
        </Link>
      </ScaleOut>

      <ScaleOut delay={0.9} duration={0.5}>
        <Link to="/contact">
          <button className="bg-white text-primaryBlue rounded-xl shadow-warm py-2 px-4 shadow-white/30 hover:scale-105 hover:shadow-none transition-all font-medium hover:font-semibold w-full md:w-[160px]">
            <span> {t("disclaimer.btn2")}</span>{" "}
          </button>
        </Link>
      </ScaleOut>
    </div>
  );
}
