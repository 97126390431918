import React from "react";
import Hero from "./Hero";
import Shape from "./Hero/Shape";
import { motion } from "framer-motion";

export default function Intro() {
  return (
    <div>
      <Hero />

      <div className="absolute hidden lg:block opacity-90 -top-[50vh] xl:scale-125 left-0 z-0">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.3, duration: 0.8, ease: "easeIn" }}
          className="w-full lg:w-fit"
          duration={0.6}
          delay={1.1}
        >
          <Shape />
        </motion.div>
      </div>
    </div>
  );
}
