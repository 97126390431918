import { motion, easeInOut } from "framer-motion";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContactBG from "../../ContactBG";

export default function Contact() {
  const { t } = useTranslation("global");

  return (
    <div className="flex items-center relative -mr-20 xl:-mr-0">
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, easings: easeInOut, delay: 0.9 }}
        style={{ right: "50px", position: "absolute" }}
        className="hidden xl:block"
      >
        <Link to="/contact">
          <p className="px-9 py-2 rounded-full text-white font-semibold bg-primaryBlue shadow-jubilation hover:shadow-none hover:brightness-125 transition-all">
            Contact
          </p>
        </Link>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, easings: easeInOut, delay: 0.9 }}
        style={{ right: "100px", position: "absolute" }}
        className="xl:hidden block"
      >
        <Link to="/contact">
          <p className="px-7 xl:px-9 py-2 rounded-full text-white font-semibold bg-primaryBlue shadow-jubilation hover:shadow-none hover:brightness-125 transition-all">
            <span>{t("nav.contact")}</span>
          </p>
        </Link>
      </motion.div>

      <ContactBG />
    </div>
  );
}
