import { useState, useEffect } from "react";
import Intro from "./components/Home/Intro";
import HowItWorks from "./components/Home/HowItWorks";
import GoodToKnow from "./components/Home/GoodToKnow";
import Downloads from "./components/Home/Downloads";
import Warnings from "./components/Home/Warnings";
import useScrollToTop from "./hooks/useScrollToTop";
import useMapState from "./components/helpers/mapState";
import { UAParser } from "ua-parser-js";
import { PiWarningCircleFill } from "react-icons/pi";
import Details from "./components/Home/Intro/Details";

export default function App() {
  const [isSafari, setIsSafari] = useState(false);
  const resetState = useMapState((state) => state.reset);

  useScrollToTop();

  useEffect(() => {
    const parser = new UAParser();

    const browserName = parser.getBrowser().name;

    setIsSafari(browserName === "Safari");
  }, []);

  useEffect(() => {
    // Reset the state when the component is mounted (or when a specific condition is met)
    resetState();
  }, [resetState]);

  useEffect(() => {
    setIsSafari(isSafari);
  }, []);

  return (
    <div className="!bg-primaryGray w-[screen] overflow-hidden">
      {isSafari && (
        <div className="fixed w-screen h-screen inset-0 flex flex-col items-center justify-center bg-primaryGray/95 z-50 text-center px-6">
          <div className="max-w-lg bg-white rounded-lg shadow-lg p-8">
            <div className="flex flex-col items-center">
              <div className="text-red-500">
                <PiWarningCircleFill className="text-8xl" />
              </div>
              <h1 className="text-xl font-bold text-gray-800 mt-4">
                Waarschuwing
              </h1>
              <p className="text-gray-600 mt-2">
                Deze website werkt helaas niet goed met de Safari-browser.
              </p>
              <p className="text-gray-600 mt-1">
                Gebruik a.u.b. een andere browser.
              </p>
              <div className="mt-6">
                <a
                  href="https://www.google.com/chrome/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-6 py-2 bg-blue-500 text-white font-medium rounded-md shadow hover:bg-blue-600 transition duration-300"
                >
                  Download Chrome
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <Intro />

      {!isSafari && (
        <>
          <Details />

          <HowItWorks />

          <GoodToKnow />

          <Downloads />

          <Warnings />
        </>
      )}
    </div>
  );
}
