import { Link } from "react-router-dom";

export default function StyledLink({ to, text, selected }) {
  return (
    <Link className="group" to={to}>
      <span>{text}</span>
      {selected ? (
        <div className="bg-secondaryOrange h-[2px] w-full"></div>
      ) : (
        <div className="bg-secondaryOrange h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
      )}
    </Link>
  );
}
