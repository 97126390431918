export default function Shape() {
  return (
    <svg
      width="100vw"
      height="851"
      viewBox="0 0 1440 851"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H1440V851L0 618.066V0Z" fill="#D3D3D3" />
      <g opacity="0.24">
        <mask
          id="mask0_29_5823"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1440"
          height="851"
        >
          <path d="M0 0H1440V851L0 618.066V0Z" fill="#F4F4F4" />
        </mask>
        <g mask="url(#mask0_29_5823)">
          <g opacity="0.44">
            <path
              opacity="0.44"
              d="M-82.4125 676.115L-228 488.208L957.407 -605L1102.99 -417.072L-82.4125 676.115Z"
              fill="url(#paint0_radial_29_5823)"
            />
            <path
              opacity="0.44"
              d="M862.692 742.595L743.002 588.112L1698.75 -293.29L1818.44 -138.827L862.692 742.595Z"
              fill="url(#paint1_radial_29_5823)"
            />
            <path
              opacity="0.44"
              d="M68.8206 117.315L22.1387 57.0604L476.326 -361.788L523.008 -301.534L68.8206 117.315Z"
              fill="url(#paint2_radial_29_5823)"
            />
            <path
              opacity="0.44"
              d="M338.352 1055.47L246.052 936.325L794.48 430.576L886.762 549.7L338.352 1055.47Z"
              fill="url(#paint3_radial_29_5823)"
            />
            <path
              opacity="0.44"
              d="M540.043 618.585L435.335 483.441L1189.5 -212.089L1294.21 -76.9442L540.043 618.585Z"
              fill="url(#paint4_radial_29_5823)"
            />
            <path
              opacity="0.44"
              d="M-105.331 1092.94L-241.925 916.62L450.56 278.006L587.154 454.311L-105.331 1092.94Z"
              fill="url(#paint5_radial_29_5823)"
            />
            <path
              opacity="0.44"
              d="M872.768 1295.43L821.869 1229.72L1236.35 847.47L1287.27 913.18L872.768 1295.43Z"
              fill="url(#paint6_radial_29_5823)"
            />
            <g opacity="0.44" filter="url(#filter0_d_29_5823)">
              <path
                d="M797.502 1068.54L719.064 967.286L1357.78 378.24L1436.23 479.491L797.502 1068.54Z"
                fill="url(#paint7_radial_29_5823)"
                shape-rendering="crispEdges"
              />
              <path
                d="M1435.56 479.435L797.563 1067.8L719.741 967.343L1357.72 378.977L1435.56 479.435Z"
                stroke="black"
                shape-rendering="crispEdges"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_29_5823"
          x="715.064"
          y="378.24"
          width="725.17"
          height="698.298"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_29_5823"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_29_5823"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_29_5823"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(365.557 -69.8117) rotate(52.2364) scale(139.756 883.077)"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_29_5823"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1221.49 138.108) rotate(52.227) scale(114.895 712.003)"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_29_5823"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(249.85 -156.287) rotate(52.2465) scale(44.8018 338.378)"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_29_5823"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(519.939 677.019) rotate(52.2374) scale(88.595 408.56)"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_29_5823"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(812.566 127.839) rotate(52.2393) scale(100.51 561.844)"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_29_5823"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(103.167 588.394) rotate(52.237) scale(131.121 515.876)"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_29_5823"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1029.37 1034.62) rotate(52.2364) scale(48.8686 308.786)"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_29_5823"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1038.86 666.615) rotate(52.2364) scale(75.3039 475.823)"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}
