import React from "react";
import ScaleOut from "../../../Common/ScaleOut";
import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export default function Buttons({
  handleSetStep,
  stepsComplete,
  setCurrentText,
  currentText,
}) {
  const { t } = useTranslation("global");
  const verified = localStorage.getItem("verified");

  function handleBack() {
    if (!verified) {
      handleSetStep(-1);
    }
    setCurrentText(currentText - 1);
  }

  function handleNext() {
    if (!verified) {
      handleSetStep(1);
    }
    setCurrentText(currentText + 1);
  }

  return (
    <div className="flex items-center justify-end gap-2">
      <ScaleOut duration={0.6} delay={0.5}>
        <button
          disabled={currentText === 0 || stepsComplete === 0}
          className="flex justify-end items-center disabled:opacity-40 gap-x-3 hover:gap-x-6 w-[120px] active:scale-95 shadow-jubilation hover:brightness-110 hover:shadow-none transition-all text-primaryBlue border-2 border-primaryBlue px-4 py-2 rounded-xl"
          onClick={handleBack}
        >
          <FaArrowRight className="rotate-180" />
          <span> {t("warningsSection.prevBtn")}</span>
        </button>
      </ScaleOut>

      <ScaleOut duration={0.6} delay={0.7}>
        <button
          onClick={handleNext}
          disabled={currentText === 8}
          className="flex items-center gap-x-3 disabled:bg-primaryBlue/70 disabled:border-primaryBlue/5 hover:gap-x-6 w-[140px] active:scale-95 shadow-jubilation hover:brightness-110 hover:shadow-none transition-all bg-primaryBlue border-2 border-primaryBlue text-white px-4 py-2 rounded-xl"
        >
          <span>
            {stepsComplete === 7 || stepsComplete === 8
              ? t("warningsSection.done")
              : t("warningsSection.nextBtn")}
          </span>{" "}
          <FaArrowRight />{" "}
        </button>
      </ScaleOut>
    </div>
  );
}
