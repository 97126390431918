import { motion, easeInOut } from "framer-motion";
import { Link } from "react-router-dom";

export default function Logo() {
  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, easings: easeInOut, delay: 0.3 }}
    >
      <Link to={process.env.PUBLIC_URL}>
        <img
          src={`${process.env.PUBLIC_URL}/header/BouwruimteNL_logo.png`}
          alt="logo"
          className="h-[60px] aspec-auto"
        />
      </Link>
    </motion.div>
  );
}
