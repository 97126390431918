import { motion, easeInOut } from "framer-motion";

export default function ContactBG() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, easings: easeInOut, delay: 0.4 }}
    >
      <svg
        width="290"
        height="80"
        viewBox="0 0 342 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.9"
          d="M54.72 0H342V93H0C21.3695 56.6812 33.3505 36.3188 54.72 0Z"
          fill="url(#paint0_linear_72_57)"
        />
        <path
          opacity="0.8"
          d="M90.9612 0H342V93H35L90.9612 0Z"
          fill="url(#paint1_linear_72_57)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_72_57"
            x1="342"
            y1="-6.92904e-05"
            x2="-6.14701"
            y2="51.2112"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D3D3D3" />
            <stop offset="0.563154" stopColor="#D3D3D3" stopOpacity="0.5" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_72_57"
            x1="83.7363"
            y1="-3.42722e-05"
            x2="350.037"
            y2="41.7986"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.373154" stopColor="#D3D3D3" stopOpacity="0.8" />
            <stop offset="0.555" stopColor="#D3D3D3" />
          </linearGradient>
        </defs>
      </svg>
    </motion.div>
  );
}
