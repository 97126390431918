import { useTranslation } from "react-i18next";
import AnimatedTitle from "../../../Common/AnimatedTitle";
import RevealingFromBottom from "../../../Common/RevealingFromBottom";
import { motion } from "framer-motion";

export default function Text() {
  const { t } = useTranslation("global");

  return (
    <div className="relative z-20">
      <AnimatedTitle
        className="text-4xl lg:text-5xl text-center hidden md:block md:text-left font-semibold text-primaryBlue leading-[1.2]"
        extraDelay={0.6}
        duration={0.5}
      >
        {t("hero.part1")}
      </AnimatedTitle>

      <div className="mt-5 lg:mt-10 space-y-2">
        <RevealingFromBottom
          extraDelay={0.5}
          duration={0.5}
          className="lg:text-4xl text-3xl font-semibold text-secondaryOrange text-center md:text-left"
        >
          {t("hero.part2")}
        </RevealingFromBottom>

        <RevealingFromBottom
          extraDelay={0.6}
          duration={0.5}
          className="text-2xl font-semibold text-primaryBlue/50 text-center md:text-left"
        >
          {t("hero.part3")}
        </RevealingFromBottom>
      </div>
    </div>
  );
}
