import { motion } from "framer-motion";

export default function Image() {
  return (
    <>
      <div className="z-10 relative">
        <motion.img
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 1, ease: "easeOut" }}
          src={`${process.env.PUBLIC_URL}/intro/heroRight.png`}
          alt="hero-right"
          className="z-10 rounded-3xl shadow-jubilation"
        />
      </div>

      <div className="absolute left-[50%] -translate-x-[50%] bottom-[60vh] lg:bottom-0 bg-secondaryBlue opacity-10 blur-xl size-[300px] rounded-full" />
    </>
  );
}
