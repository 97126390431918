import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { SingleAcc } from "./SingleAcc";
import { useScrollAnimate } from "../../../hooks/useScrollAnimate";
import AnimatedTitle from "../../Common/AnimatedTitle";

export default function GoodToKnow() {
  const { t } = useTranslation("global");
  const { ref, controls } = useScrollAnimate();

  const goodToKnow = t("goodToKnow.content", { returnObjects: true });

  return (
    <div ref={ref} id="about" className="relative px-2 md:px-4 lg:px-0">
      <div className="px-5 lg:px-14 xl:px-32 my-16 py-16 z-10 relative">
        <div className="mx-auto">
          <AnimatedTitle className="mb-4 text-center text-3xl font-semibold text-primaryBlue">
            {t("goodToKnow.header")}
          </AnimatedTitle>

          <motion.div
            initial="hidden"
            animate={controls}
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: { opacity: 1, y: 0 },
            }}
            transition={{ delay: 0.2, duration: 0.5, ease: "easeIn" }}
            className="grid lg:grid-cols-2 lg:gap-8"
          >
            <div>
              {goodToKnow.slice(0, 4).map((singleData) => (
                <SingleAcc title={singleData.title}>
                  {singleData.content.map((singleContent) => (
                    <p className="mt-1">{singleContent}</p>
                  ))}
                </SingleAcc>
              ))}
            </div>

            <motion.div
              initial="hidden"
              animate={controls}
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 },
              }}
              transition={{ delay: 0.2, duration: 0.5, ease: "easeIn" }}
            >
              {goodToKnow.slice(4, 8).map((singleData) => (
                <SingleAcc title={singleData.title}>
                  {singleData.content.map((singleContent) => (
                    <p className="mt-1">{singleContent}</p>
                  ))}
                </SingleAcc>
              ))}
            </motion.div>
          </motion.div>
        </div>
      </div>

      <div className="absolute rounded-full -left-28 rotate-45 top-80 lg:top-10 bg-secondaryBlue h-[300px] w-[400px] blur-xl opacity-10" />

      <div className="absolute rounded-full -right-32 -rotate-45 top-40 bg-secondaryOrange h-[300px] w-[300px] blur-xl opacity-10" />
    </div>
  );
}
