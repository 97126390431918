import { IoCloseSharp } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { easeInOut, motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { handleClickScroll } from "../../../../helpers/handleClickScroll";

import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa";

export default function Navigation() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("global");
  const location = useLocation();

  const menuVariants = {
    open: {
      right: 0,
      transition: { type: "spring", stiffness: 400, damping: 50 },
    },
    closed: {
      right: "-100vw", // Assuming the menu is full width of the viewport
      transition: { type: "spring", stiffness: 400, damping: 50 },
    },
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, easings: easeInOut, delay: 0.5 }}
        className="flex items-center"
      >
        <button
          onClick={() => setOpen(!open)}
          className="z-10 bg-primaryBlue rounded px-3 py-1.5"
        >
          <GiHamburgerMenu className="text-2xl text-white" />
        </button>
      </motion.div>

      <AnimatePresence>
        {open && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
            className="fixed top-0 h-full w-3/4 bg-white shadow-lg z-[1000]"
          >
            <button
              className="absolute top-5 right-5 text-xl"
              onClick={() => setOpen(false)}
            >
              <IoCloseSharp className="text-3xl" />
            </button>

            <nav className="flex flex-col mt-14 space-y-2">
              <Link
                to={process.env.PUBLIC_URL}
                onClick={() => setOpen(false)}
                className="flex items-center cursor-pointer px-6 py-2 text-primaryBlue font-semibold text-xl hover:scale-105 transition-all rounded-md"
              >
                Startpagina
              </Link>

              <div className="mx-6 h-px bg-gray-300" />
              {location.pathname === "/" ||
              location.pathname === "/acceptance" ||
              location.pathname === "/acceptance/" ? (
                <>
                  <p
                    onClick={() => {
                      handleClickScroll("howworks");
                      setOpen(false);
                    }}
                    className="flex items-center cursor-pointer px-6 py-2 text-primaryBlue font-semibold text-xl hover:scale-105 transition-all rounded-md"
                  >
                    {t("nav.wedo")}
                  </p>
                  <div className="mx-6 h-px bg-gray-300" />
                  <p
                    onClick={() => {
                      handleClickScroll("about");
                      setOpen(false);
                    }}
                    className="flex items-center cursor-pointer px-6 py-2 text-primaryBlue font-semibold text-xl hover:scale-105 transition-all rounded-md"
                  >
                    {t("nav.about")}
                  </p>{" "}
                </>
              ) : (
                <>
                  <Link
                    to={process.env.PUBLIC_URL}
                    onClick={() => setOpen(false)}
                    className="flex items-center cursor-pointer px-6 py-2 text-primaryBlue font-semibold text-xl hover:scale-105 transition-all rounded-md"
                  >
                    {t("nav.wedo")}
                  </Link>

                  <div className="mx-6 h-px bg-gray-300" />

                  <Link
                    to={process.env.PUBLIC_URL}
                    onClick={() => setOpen(false)}
                    className="flex items-center cursor-pointer px-6 py-2 text-primaryBlue font-semibold text-xl hover:scale-105 transition-all rounded-md"
                  >
                    {t("nav.about")}
                  </Link>
                </>
              )}

              <div className="mx-6 h-px bg-gray-300" />

              <Link
                to="/disclaimer"
                onClick={() => setOpen(false)}
                className="flex items-center cursor-pointer px-6 py-2 text-primaryBlue font-semibold text-xl hover:scale-105 transition-all rounded-md"
              >
                {t("nav.disclaimer")}
              </Link>

              <div className="mx-6 h-px bg-gray-300" />

              <Link
                to="/question"
                onClick={() => setOpen(false)}
                className="flex items-center cursor-pointer px-6 py-2 text-primaryBlue font-semibold text-xl hover:scale-105 transition-all rounded-md"
              >
                {t("nav.faqs")}
              </Link>

              <div className="mx-6 h-px bg-gray-300" />

              <Link
                to="/contact"
                onClick={() => setOpen(false)}
                className="flex items-center cursor-pointer px-6 py-2 text-primaryBlue font-semibold text-xl hover:scale-105 transition-all rounded-md"
              >
                {t("nav.contact")}
              </Link>

              <div className="text-center !mt-10 text-2xl font-semibold text-gray-500 font-serif">
                Links
              </div>

              <div className="w-[30%] mx-auto h-px bg-gray-300" />

              <div className="flex items-center justify-center !mt-3 gap-x-4">
                <a
                  href="tel:0610503192"
                  className="bg-gray-600 p-2 rounded-full text-white"
                >
                  <FaPhoneAlt className="text-xl" />
                </a>

                <a
                  href="mailto:info@bouwruimte.nl"
                  className="bg-gray-600 p-2 rounded-full text-white"
                >
                  <IoMail className="text-xl" />
                </a>

                <a
                  href="https://www.linkedin.com/company/bouwruimte-nl/posts/?feedView=all"
                  className="bg-gray-600 p-2 rounded-full text-white"
                >
                  <FaLinkedin className="text-xl" />
                </a>
              </div>
            </nav>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
