import { useState, useEffect } from "react";
import { BiCookie } from "react-icons/bi";
import { motion } from "framer-motion";

export default function CookiePopup() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsentGiven");
    if (consent !== "true") {
      setShowPopup(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsentGiven", "true");
    setShowPopup(false);
  };

  if (!showPopup) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 1 }}
      className="fixed top-0 left-0 w-screen h-screen bg-black backdrop-blur-sm bg-opacity-50 z-[10000]"
    >
      <div className="relative w-screen h-screen">
        <div className="absolute flex flex-col items-center top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-50 bg-white rounded-2xl shadow-intense px-6 py-16 text-white w-[90%] lg:w-[500px]">
          <div className="bg-gray-400 rounded-full w-24 h-24 mb-8 flex items-center justify-center">
            <BiCookie className="text-[70px] text-white" />
          </div>

          {/* <img
            className="h-32"
            src={`${process.env.PUBLIC_URL}/cookie.png`}
            alt=""
          /> */}

          <p className="text-3xl font-bold text-black mb-6">
            Cookiebeleid Melding
          </p>

          <p className="text-lg text-center text-black/70 mb-10">
            Deze site maakt enkel gebruik van functionele cookies die nodig zijn
            om de site goed te laten werken. Accepteer deze om verder te gaan.
          </p>
          <button
            onClick={handleAccept}
            className="bg-primaryBlue text-white px-14 py-3 text-[17px] rounded-full font-semibold hover:scale-105 transition-all"
          >
            Akkoord
          </button>
        </div>
      </div>{" "}
    </motion.div>
  );
}
