import { useTranslation } from "react-i18next";
import AnimatedTitle from "../../Common/AnimatedTitle";
import SingleCard from "./SingleCard";

export default function Downloads() {
  const { t } = useTranslation("global");

  const data = [
    {
      img: `${process.env.PUBLIC_URL}/downloads/charts1.png`,
      title: t("chars.1"),
    },
    {
      img: `${process.env.PUBLIC_URL}/downloads/charts2.png`,
      title: t("chars.2"),
    },
    {
      img: `${process.env.PUBLIC_URL}/downloads/charts3.png`,
      title: t("chars.3"),
    },
  ];

  return (
    <div className="bg-[#D9D9D9] px-2 sm:px-40 md:px-12 lg:px-6 xl:px-32 lg:my-20 xl:my-0 shadow-jubilation py-10">
      <AnimatedTitle className="text-2xl lg:text-4xl px-10 lg:px-0 font-semibold text-primaryBlue text-center">
        Voorbeelden en instructies
      </AnimatedTitle>

      <div className="grid md:grid-cols-2 px-4 lg:px-0 lg:grid-cols-3 gap-10 mt-10 lg:mt-20">
        {data.map((singleCard, index) => (
          <SingleCard index={index} singleCard={singleCard} />
        ))}
      </div>
    </div>
  );
}
