import React from "react";
import FollowUs from "./FollowUs";
import Links from "./Links";

export default function Footer() {
  return (
    <footer className="bg-gray-900 relative z-30">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="grid gap-10 lg:gap-24 lg:grid-cols-3">
            <Links />

            <FollowUs />
          </div>
        </div>

        <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8" />
      </div>
    </footer>
  );
}
