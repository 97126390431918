import { useTranslation } from "react-i18next";
import Steps from "./Steps";
import MustRead from "./MustRead";
import RevealingFromBottom from "../../../Common/RevealingFromBottom";
import { useScrollAnimate } from "../../../../hooks/useScrollAnimate";
import WarningIcon from "./WarningIcon";
import Title from "./Title";
import Buttons from "./Buttons";
import { useEffect, useState } from "react";

export default function WarningsBox({ stepsComplete, setStepsComplete, read }) {
  const { t } = useTranslation("global");
  const { ref } = useScrollAnimate();

  const [currentText, setCurrentText] = useState(stepsComplete);

  const numSteps = 8;

  const warnings = t("warningsSection.content", { returnObjects: true });

  console.log({ currentText, stepsComplete });

  const handleSetStep = (num) => {
    if (
      (stepsComplete === 0 && num === -1) ||
      (stepsComplete === numSteps && num === 1)
    ) {
      return;
    }

    setStepsComplete((pv) => pv + num);
  };

  useEffect(() => {
    const verified = localStorage.getItem("verified");

    if (verified) {
      setStepsComplete(8);
    }
  }, []);

  useEffect(() => {
    if (stepsComplete === 8) {
      setCurrentText(8);
    }
  }, [stepsComplete]);

  return (
    <div ref={ref} id="warnings" className="mb-40 relative z-10">
      <Title />

      <div className="max-w-4xl flex flex-col mx-auto relative z-10">
        <Steps numSteps={numSteps} stepsComplete={stepsComplete} />

        <div className="p-2 lg:p-6 space-y-4 my-6 h-64 lg:h-48 font-medium border-gray-200 rounded-lg">
          {currentText === 8
            ? warnings
                .at(7)
                .split("|")
                .map((line, index) => (
                  <RevealingFromBottom duration={0.6} extraDelay={index / 20}>
                    {line}
                  </RevealingFromBottom>
                ))
            : warnings
                .at(currentText)
                .split("|")
                .map((line, index) => (
                  <RevealingFromBottom duration={0.6} extraDelay={index / 20}>
                    {line}
                  </RevealingFromBottom>
                ))}
        </div>

        <Buttons
          currentText={currentText}
          setCurrentText={setCurrentText}
          stepsComplete={stepsComplete}
          handleSetStep={handleSetStep}
        />
      </div>

      <WarningIcon />

      <img
        className="h-[400px] aspect-auto absolute -bottom-20 -right-20 opacity-10 blur-3xl"
        src={`${process.env.PUBLIC_URL}/warning/warningsShape.svg`}
        alt=""
      />

      <MustRead read={read} />
    </div>
  );
}
