import { easeInOut, motion } from "framer-motion";
import { Link } from "react-router-dom";
import Navigation from "./Navigation";
import ContactBG from "./ContactBG";

export default function MobileHeader() {
  return (
    <>
      <div className="flex z-[1000] relative lg:hidden overflow-hidden justify-between bg-primaryGray shadow  p-3">
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 2, easings: easeInOut, delay: 0.3 }}
        >
          <Link to={process.env.PUBLIC_URL}>
            <img
              src={`${process.env.PUBLIC_URL}/header/BouwruimteNL_logo.png`}
              alt="logo"
              className="h-[45px] aspect-auto"
            />
          </Link>
        </motion.div>

        <Navigation />

        <ContactBG />
      </div>
    </>
  );
}
