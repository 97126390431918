import { motion } from "framer-motion";
import { useScrollAnimate } from "../../../hooks/useScrollAnimate";

const SingleCardMobile = ({ title, content, index, open, setOpen }) => {
  const isOpen = index === open;
  const { ref, controls } = useScrollAnimate();

  return (
    <motion.div
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: -20, x: -20 },
        visible: { opacity: 1, y: 0, x: 0 },
      }}
      transition={{ delay: index / 10 + 0.1, duration: 0.6, ease: "easeIn" }}
      ref={ref}
      onClick={() => setOpen(index)}
      className="p-0.5 rounded-lg relative overflow-hidden cursor-pointer"
    >
      <motion.div
        initial={false}
        animate={{
          height: isOpen ? "320px" : "80px",
        }}
        className={`p-4 rounded-[7px] ${
          isOpen
            ? "bg-white/80 hover:bg-white/90"
            : "bg-white/50 hover:bg-white/70"
        }  flex flex-col justify-between relative z-20 transition-colors`}
      >
        <div>
          <motion.p
            initial={false}
            animate={{
              color: isOpen ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 1)",
            }}
            className="text-base xl:text-lg font-semibold w-fit bg-primaryBlue bg-clip-text"
          >
            {title}
          </motion.p>
          <motion.p
            initial={false}
            animate={{
              opacity: isOpen ? 1 : 0,
            }}
            className="mt-4 bg-primaryBlue/70 bg-clip-text text-transparent text-sm xl:text-base"
          >
            {content}
          </motion.p>
        </div>
      </motion.div>
      <motion.div
        initial={false}
        animate={{
          opacity: isOpen ? 1 : 0,
        }}
        className="absolute inset-0 z-10 "
      />
      <div className="absolute inset-0 z-0 bg-slate-200" />
    </motion.div>
  );
};

export default SingleCardMobile;
